import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '~/actions/ModalActions';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import type { AuthModalProps } from '~/components/common/auth/AuthModal/AuthModal';

const {
  EVENTS: { MODAL_TYPE_AUTH, MODAL_TYPE_TWOFA_ACTION },
} = NAV_DATA;

type AuthCallbacks = {
  loginCallback?: AuthModalProps['loginCallback'];
  signupCallback?: AuthModalProps['signupCallback'];
  signupLinkCallback?: AuthModalProps['signupLinkCallback'];
  closeCallback?: () => void; // TODO: Is this doing anything?
};

export function useExposeAuthModal(): void {
  const dispatch = useDispatch();
  // Allow web-apps to open authModal:

  useEffect(() => {
    window.zolaNav = window.zolaNav || {};
  }, []);

  useEffect(() => {
    const openAuthModal = (opts: AuthCallbacks, authState: 'login' | 'signup') => {
      let loginCallback;
      let signupCallback;
      let signupLinkCallback;
      let closeCallback;

      if (typeof opts === 'function') {
        loginCallback = opts;
      } else if (typeof opts === 'object') {
        loginCallback = opts.loginCallback; // eslint-disable-line
        signupCallback = opts.signupCallback; // eslint-disable-line
        signupLinkCallback = opts.signupLinkCallback; // eslint-disable-line
        closeCallback = opts.closeCallback; // eslint-disable-line
      }
      const ariaLabel = authState === 'login' ? 'Log In' : 'Sign Up';

      return dispatch(
        showModal(
          MODAL_TYPE_AUTH,
          { authState, loginCallback, signupCallback, signupLinkCallback, closeCallback },
          { size: 'sm', ariaLabel, v2Styles: true }
        )
      );
    };

    window.zolaNav.openLoginModal = (opts: AuthCallbacks) => openAuthModal(opts, 'login');
    window.zolaNav.openSignupModal = (opts: AuthCallbacks) => openAuthModal(opts, 'signup');
    window.zolaNav.openTwoFAModal = (opts: AuthCallbacks) =>
      dispatch(
        showModal(MODAL_TYPE_TWOFA_ACTION, opts, {
          size: 'sm',
          ariaLabel: 'Enter your verification code',
        })
      );

    return () => {
      window.zolaNav.openLoginModal = () => undefined;
      window.zolaNav.openSignupModal = () => undefined;
      window.zolaNav.openTwoFAModal = () => undefined;
    };
  }, [dispatch]);
}
