import { useEffect } from 'react';
import { checkUserFlag, setUserSetting } from '@zola-helpers/client/dist/es/util/userSettings';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '~/actions/ModalActions';
import { isPostAuthNav3Desktop } from '~/components/navV3/PostAuth/utils';
import { getUserCreatedAt } from '~/selectors/user';
import { isBeforeDate } from '@zola-helpers/client/dist/es/util/dateUtils';

export const usePostAuthDesktopNavRolloutModal = (userContext: UserContext): void => {
  const USER_SETTINGS = 'hasSeenPostAuthDesktopNavRolloutModal';

  const userCreatedAt = useSelector(getUserCreatedAt);

  const dispatch = useDispatch();

  useEffect(() => {
    // do not show the rollout modal to new users
    const rolloutDate = new Date('7/10/2023');
    const userCreatedBeforeRollout = userCreatedAt
      ? isBeforeDate(userCreatedAt, rolloutDate)
      : false;
    // TODO: remove this check and move homesotre nav into web-store.
    const isHomestore =
      typeof window !== 'undefined' && window.location.href.indexOf('homestore') > -1;
    const shouldShowModal =
      isPostAuthNav3Desktop(userContext) && userCreatedBeforeRollout && !isHomestore;
    if (shouldShowModal) {
      checkUserFlag(USER_SETTINGS)
        .then((hasSeenModal) => {
          if (!hasSeenModal) {
            setUserSetting(USER_SETTINGS).catch(() => undefined);
            dispatch(showModal('POST_AUTH_DESKTOP_NAV_ROLLOUT', {}, { size: 'sm' }));
          }
        })
        .catch(() => undefined);
    }
  }, [dispatch, userContext, userCreatedAt]);
};
