import { AnyAction } from 'redux';
import { receivedCartSizeAction, requestCartSizeAction } from '~/actions/types/CartActionTypes';

type CartState = {
  busy: boolean;
  size: number;
};

const initialState: CartState = {
  busy: false,
  size: 0,
};

const cartReducer = (state = initialState, action: AnyAction): CartState => {
  if (requestCartSizeAction.match(action)) {
    return { ...state, busy: true };
  }

  if (receivedCartSizeAction.match(action)) {
    return { ...state, busy: false, size: action.payload };
  }

  return state;
};

export default cartReducer;
