import type { ModalOptions, ModalProps } from '~/reducers/modal';
import * as ActionType from './types/ModalActionTypes';

export const hideModal = () => ({ type: ActionType.HIDE_MODAL });

export const showModal = (
  modalType: string,
  modalProps: ModalProps = {},
  modalOptions: ModalOptions = {}
) => ({
  type: ActionType.SHOW_MODAL,
  payload: {
    modalType,
    modalProps,
    modalOptions,
  },
});
